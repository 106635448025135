<template>
    <div>

      <Header @selCat="selCat" @selPrice="selPrice"></Header>


      <Footer @setPages="setPages" msg="right" ref="footPagesR"></Footer>
      <div class="goodsList" v-show="goodsList.length > 0">
        <div class="goodsList_div" v-for="(item, index) in goodsList" :key="index">
          <div class="goodsList_div_imgdiv">

            <div class="goodsList_div_imgdiv_icon">
              <img v-for="(fname,index) in item.iconArr" height="30" :src="fname" :key="index" alt="">
            </div>

            <div class="goodsList_div_imgdiv_goodsImg">
              <img :src="`http://hwimg.xmvogue.com/thumb/${item.item_no}.jpg?x-oss-process=style/440`"
                   style="width: 318px;height: 318px;" @click="clickImg(item)" alt="" />
            </div>

          </div>
          <div class="goodsList_div_bottom">
            <div class="prod_title">
              <span style="color: #000000; font-size: 14px; margin-right: 5px">{{ item.item_no }}</span>
              <i class="el-icon-document-copy" v-clipboard:copy="item.item_no" v-clipboard:success="onCopy"
                 v-clipboard:error="onError"></i>
            </div>
            <div class="prod_title">
              <el-tooltip :content="item.item_en" placement="bottom" effect="light">
                <p style="color: #000000; font-size: 15px; margin-right: 5px">
                  {{ (lang === "zh-CN" ? item.item_name : item.item_en) | cutstr }}
                </p>
              </el-tooltip>

            </div>
            <div class="prod_title_price" v-if="userInfo.pid === 0 || userInfo.show_price === 1">
              <template v-if="item.did_good==null">
                <div class="prod_title">
                  <div style="font-size: 14px; color: #000000;">{{ $t("PURCHASE PRICE") }}:</div>
                  <div style="font-size: 14px; color: #d92524; margin-left: 5px;">{{ $t("Price symbol") }}{{ item.base_price.toFixed(2) }}</div>
                </div>
                <div class="prod_title">
                  <div style="font-size: 14px; color: #000000; margin-left: 5px">{{ $t("RETAIL PRICE") }}:</div>
                  <div style="font-size: 14px; color: #d92524; margin-left: 5px">{{ $t("Price symbol") }}{{ item.sale_price.toFixed(2) }}</div>
                </div>
              </template>
              <!-- 折扣商品展示 start -->
              <template v-if="item.did_good!=null">
                <div class="prod_title">
                  <div style="font-size: 14px; color: #000000;text-decoration:line-through">{{ $t("PURCHASE PRICE") }}:</div>
                  <div style="font-size: 14px; color: #d92524; margin-left: 5px;text-decoration:line-through">{{ $t("Price symbol") }}{{ item.base_price.toFixed(2) }}</div>
                </div>
                <div class="prod_title">
                  <div style="font-size: 14px; color: #000000; margin-left: 5px">{{ $t("RETAIL PRICE") }}:</div>
                  <div style="font-size: 14px; color: #d92524; margin-left: 5px">{{ $t("Price symbol") }}{{ item.sale_price.toFixed(2) }}</div>
                </div>
                <template v-if="disList[parseInt(item.did_good.dis_id)] === 2">
                  <div class="prod_title">
                    <div style="font-size: 14px; color: #000000;text-decoration:line-through">{{ $t("Discount Price") }}:</div>
                    <div style="font-size: 14px; color: #d92524; margin-left: 5px;text-decoration:line-through">{{ $t("Price symbol") }}{{ item.did_good.dis_price.toFixed(2) }}</div>
                  </div>
                </template>
                <template v-else-if="disList[parseInt(item.did_good.dis_id)] === 3">
                  <!--  -->
                  <div class="prod_title">
                    <div style="font-size: 14px; color: #000000;">{{ $t("Discount Price") }}:</div>
                    <div style="font-size: 14px; color: #d92524; margin-left: 5px;">{{ $t("Price symbol") }}{{ item.did_good.fixed_price.toFixed(2) }}</div>
                  </div>
                </template>
                <template v-else>
                  <div class="prod_title">
                    <div style="font-size: 14px; color: #000000;">{{ $t("Discount Price") }}:</div>
                    <div style="font-size: 14px; color: #d92524; margin-left: 5px;">{{ $t("Price symbol") }}{{ item.did_good.dis_price.toFixed(2) }}</div>
                  </div>
                </template>
              </template>
              <!--  -->
              <div class="prod_title" v-if="item.did_good!=null && (disList[parseInt(item.did_good.dis_id)] === 2) ">
                <img v-if="lang === 'zh-CN'" :src="require('@/assets/images/dis/bigsellCN.png')" alt=""style="width:60px">
                <img v-if="lang === 'en'" :src="require('@/assets/images/dis/bigsale.png')" alt="" style="width:60px">
                <div style="font-size: 14px; color: #000000;">{{ $t("Explosive Price") }}:</div>
                <div style="font-size: 14px; color: #d92524;">{{ $t("Price symbol") }}{{ item.did_good.fixed_price.toFixed(2) }}</div>
              </div>
              <!-- 折扣商品展示 end -->
            </div>


            <div class="change_num">
              <el-input-number v-model="item.addNum" :min="item.purchase_spec" :max="item.us_num" :step="item.purchase_spec" />
            </div>
            <div class="prod_handle">
              <div class="prod_handle_add">
                <p class="prod_handle_title">
                  <a @click="addCarts(item)">{{ $t("Add to Cart") }}</a>
                  <a v-if="item.user_cart_info"
                     style="margin-left: 8px">[{{ item.user_cart_info.num }}]</a>
                  <a v-else style="margin-left: 8px">[0]</a>
                  <i class="el-icon-delete" style="margin-left: 8px" @click="delteCartGoods(item.item_no)"></i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="notFindGoods" v-show="goodsList.length === 0">
        {{ $t("No products of this type found") }}
      </div>
      <Footer @setPages="setPages" ref="footPagesB"></Footer>
    </div>
  </template>
  <script>
  import themezoneApi from "@/api/web/themezone/themezone.js";
  import Header from "@/views/web/themezone/header/index.vue";

  import common from "@/common/mixins/common.js";
  import { AddToCartByGoods } from "@/api/carts/carts.js";
  import Footer from "@/views/web/allGoods/footer/index.vue";
  import { DeleteGoods2Carts } from "@/api/carts/carts";
  import { getDisList } from "@/api/goods/goods.js";


  export default {
    mixins: [common],
    components: {
      Footer,
      Header,
    },
    data() {
      return {
        num: [],
        goodsList: [],
        typeList: [],
        queryData: {
          theme_zone_id: "",
          page: 1,
          pageSize: 16,
          GtClsNo:"",
          type_field:"",
          priceBegin:"",
          priceEnd:"",
        },
        lang: this.$i18n.locale,
        themeInfo:{},
        bannerUrl: "",

        QUERY_ipId: 0,
        currentItem:{},
        userInfo:{}
      };
    },
    watch: {
        $route(to, from) {
            const user = window.sessionStorage.getItem("webUser");
            const userArr = JSON.parse(user);
            this.userInfo = userArr.user_info;
            this.getData();
            this.handleGetInfo();
          },
    },
    created() {
      const user = window.sessionStorage.getItem("webUser");
      const userArr = JSON.parse(user);
      this.userInfo = userArr["user_info"];
      this.getData();
      this.handleGetInfo();
      this.getDiscountData() //获取折扣数据类型
    },
    methods: {
        // 获取打折数据，判断搜索展示
        getDiscountData() {
          getDisList().then((res) => {
            let resDataList = res.data.data;
            let disList = {};
            for (let i = 0; i < resDataList.length; i++) {
              disList[resDataList[i].id]=resDataList[i].dis_type;
            }

            this.disList = disList;
            // console.log(this.disList)
          });
        },
        //设置价格 begin
        selPrice(val) {
          switch (val.key) {
            case "all":
              this.queryData.priceBegin="";
              this.queryData.priceEnd="";
              this.getData();
              break;
            case "1":
              this.queryData.priceBegin="0";
              this.queryData.priceEnd="10";
              this.getData();
              break;
            case "2":
              this.queryData.priceBegin="11";
              this.queryData.priceEnd="20";
              this.getData();
              break;
            case "3":
              this.queryData.priceBegin="21";
              this.queryData.priceEnd="40";
              this.getData();
              break;
            case "4":
              this.queryData.priceBegin="41";
              this.queryData.priceEnd="60";
              this.getData();
              break;
            case "5":
              this.queryData.priceBegin="60";
              this.queryData.priceEnd="1000";
              this.getData();
              break;
          }
        },
      //设置
      selCat(value) {
        this.setType(value.cls_no,value.type_field);
      },
      //设定类型
      setType(id,typeField) {
        this.queryData.GtClsNo=id;
        this.queryData.page=1;
        this.queryData.type_field = typeField
        this.getData();
      },

      //复制商品条码
      onCopy: function() {
        this.$message.success("Product barcode copied to clipboard");
      },
      onError: function() {
        this.$message.success("Copy failed");
      },
      //删除购物车处理
      delteCartGoods(sku) {
        DeleteGoods2Carts(sku).then(() => {
          this.$message.success("successfully deleted");
          this.getData();
          this.$emit("initCart");
        });
      },
      //加入购物车
      addCarts(row) {
        if (row.addNum % row.purchase_spec !== 0) {
          this.$message.error(
            this.$t("Please change in multiples of specifications"),
          );
          this.$nextTick(() => {
            row.addNum=row.purchase_spec;
          });
        } else {
          AddToCartByGoods(row.item_no, row.addNum, row.purchase_spec).then(() => {
              this.getData();
              this.$emit("initCart");
          });
        }

      },
      //设置翻页
      setPages(page) {
        this.queryData.page=page;
        this.getData();
      },
      //获取列表产品
      getData() {

        var query=this.$route.query;
        this.queryData.theme_zone_id = query.id

        let queryData=this.queryData;
        if (parseInt(query.id) > 0) {
          queryData.theme_zone_id=query.id;
        }

        queryData.page=this.queryData.page;
        queryData.pageSize=this.queryData.pageSize;

        themezoneApi.getGoodsList(queryData).then((res) => {
          let resData=res.data;

          //设置分页
          this.$refs.footPagesB.setPages(
            resData.data.page,
            resData.data.total,
            resData.data.page_size,
          );
          //设置分页
          this.$refs.footPagesR.setPages(
            resData.data.page,
            resData.data.total,
            resData.data.page_size,
          );

          this.goodsList=this.filterGoods(resData.data.data);
        }).catch(() => {
          this.goodsList=[];
          this.$message.error("Unable to find product"); //无法找到产品
        });
      },
      //设定一个添加购物车的数据，用来绑定添加购物车的数量
      filterGoods(goodsData) {
        let resData=goodsData;
        for (let i=0; i < resData.length; i++) {
          resData[i].addNum=resData[i].purchase_spec;
          resData[i].iconArr=resData[i].file_type_icon_list.split(",");
        }
        return resData;
      },
      //跳转到详情页
      clickImg(row) {
        let {
          href,
        }=this.$router.resolve({
          path: "/web/goodsDetail",
          query: {
            id: row.item_no,
          },
        });
        window.open(href);
      },
      // 获取海报banner图
      handleGetInfo() {
        const theme_zone_id=this.queryData.theme_zone_id;
        if (parseInt(theme_zone_id) > 0) {
            themezoneApi.getDetail(theme_zone_id).then((res) => {
            const data=res.data.data;
            this.themeInfo = data
          });
        }
      },




      // updateNumber(val){
      //   this.currentItem.addNum = val
      // }
    },
  };
  </script>
  <style lang="scss" scoped>
  .goodsList {
    width: 1400px;
    margin: 0 auto;
    background-color: #f5f8fa;
    display: grid;
    grid-template-columns: 320px 320px 320px 320px;
    grid-row-gap: 32px;
    grid-column-gap: 32px;

    &_div {
      // background: yellow;
      border: 1px solid #ccc;

      &_imgdiv {
        min-height: 320px;
        position: relative;

        //图标
        &_icon {
          position: absolute;
          top: 2px;
          right: 2px;

          img {
            border: 1px solid #ccc;
            margin-left: 3px;
          }
        }

        //商品图标
        &_goodsImg {
          cursor: pointer;
          text-align: center;
          border-bottom: 1px solid #ccc;
          // min-height: 320px;
          height: 318px;
          width: 319px;
        }
      }

      //文字按钮样式
      &_bottom {
        text-align: center;
        margin-top: 5px;
      }

    }
  }

  .prod_title,.prod_title_price {
    width: 320px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .prod_title_price{
    height: auto !important;
    display: grid !important;
  }

  .change_num {
    //width: 356px;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .prod_handle {
    //width: 356px;
    height: 40px;
    background-color: #ddedf0;
    margin-top: 5px;

    &_add {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
    }

    &_title {
      color: #218da0;
      font-size: 16px;
      border-bottom: 1px solid #218da0;
    }
  }

  .notFindGoods {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
  }

  .banner {
    text-align: center;
    padding-top: 20px;
  }

  </style>
