import axios from "axios";

export default {
  getGoodsList: (params) => {
    return axios.get("/w1/theme/getGoodsList", { params: params });
  },
  getDetail: (id) => {
    return axios.get(`/w1/theme/${id}`);
  },
};
