import axios from "axios";

//获取热门产品
export function GetTypeByPid(pid) {
  return axios.get(`/w1/goodsType/pid/${pid}`);
}


//获取到所有的 产品
export function getAllType() {
  return axios.get(`/w1/goods/goodsType/all`);
}

export function getTreeType() {
  return axios.get("/w1/goodsType/tree");
}

export function GetTreeTypeStatis(id) {
  return axios.get(`/w1/goodsType/tree/statis`, { params: { position: id } });
}
