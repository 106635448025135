<template>
    <div :class="className">
      <div class="block">
        <el-pagination
          style="padding: 20px"
          @size-change="handleSizeChange"
          v-show="pages.total > 0"
          @current-change="handleCurrentChange"
          :current-page="pages.page"
          :page-size="pages.pageSize"
          layout="total,  prev, pager, next, jumper"
          :total="pages.total"
          background
        >
        </el-pagination>
      </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      currentPage4: 4,
      pages: {
        page: 1,
        pageSize: 16,
        total: 0,
      },
      className: "",
    };
  },
  props: {
    msg: {
      type: String,
      default: "center",
    },
  },
  created() {
    this.showPagesStyle();
  },
  methods: {
    showPagesStyle() {
      //console.log(this.msg)
      if (this.msg === "center") {
        this.className = "allgoods_footer";
      } else {
        this.className = "allgoods_footer_right";
      }
    },
    setPages(page, total, pageSize) {
      let ps = pageSize ? pageSize : 16;
      this.pages.page = page;
      this.pages.total = total;
      this.pages.pageSize = ps;
    },
    handleSizeChange(val) {
      //this.$emit("setPages",setPages)
    },
    handleCurrentChange(val) {
      this.$emit("setPages", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.allgoods_footer {
  width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.allgoods_footer_right {
  width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
}
</style>
<style lang="scss">
.allgoods_footer
  .el-pagination.is-background
  .el-pager
  li:not(.disabled).active {
  background-color: #218da0; //修改后的背景图颜色
  color: #fff;
}
.allgoods_footer_right
  .el-pagination.is-background
  .el-pager
  li:not(.disabled).active {
  background-color: #218da0; //修改后的背景图颜色
  color: #fff;
}
</style>
